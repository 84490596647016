/**
 * Clickable blocks - entire block will be clickable
 * Styles are in _helpers.scss under .block--linked
 *
 * Usage:
 * {{#link}} data-clickable="{{link_path}}"{{/link}}
 * {{#url}} data-clickable="{{href}}"{{/url}}
 */
var site = site || {};
(function($) {
  Drupal.behaviors.clickable = {
    attached: false,
    attach: function(context) {
      var self = this;
      var $theClickables = $('[data-clickable!=""][data-clickable]', context);
      $theClickables.each(function() {
        var $clickable = $(this);
        var disableClick = false;
        var url = $clickable.data('clickable');
        var clickableBlockAttributes = {
          tabindex: '0',
          role: 'link'
        };

        $clickable.attr(clickableBlockAttributes).prepend(`<a href="${url}" class="hidden"></a>`);
        if ($clickable.parents('.js-basic-carousel__slide').length) {
          $clickable.on('mousedown', function() {
            disableClick = false;
          }).on('mousemove', function() {
            disableClick = true;
          });
        }
        $clickable.once('clickable').addClass('block--linked').on('click keydown', function (e) {
          if (!self.clickOrEnter(e) || $(e.target).closest('.button').length > 0 || disableClick) {
            return;
          }
          if (window.location.hash && window.location.hash !== site.historyReplacedState) {
            history.replaceState('', '', $(this).data('clickable')); // fix for hitchhiking hashes
            window.location.href = $(this).data('clickable');
            location.reload();
          } else {
            window.location.href = $(this).data('clickable');
          }
        });
      });

      // Method can be fired multiple times but don't attach events each time
      if (!self.attached) {
        self.attached = true;
        site.historyReplacedState = '';
        $(document).off('clickableBlocks.reattach').on('clickableBlocks.reattach', function(event, $element) {
          self.attach($element);
        });

        // Monkey patching replace state to store the replacing hash value.
        var replaceState = history.replaceState;
        history.replaceState = function() {
          if (arguments[2] && typeof arguments[2] === 'string' && arguments[2].match(/^#/)) {
            site.historyReplacedState = arguments[2];
          }
          replaceState.apply(history, arguments);
        };
      }
    },
    clickOrEnter: function (e) {
      return e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter');
    }
  };
})(jQuery);
